@import '../layout/Responsive.scss';

.modal_backdrop{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #3c3c3cb8;
    z-index: 500;
    display: flex;
    justify-content: center;
    align-items: center;

    @include size_S{
        justify-content: center;
        align-items: flex-end;
    }

    &  > .modal_box{
        width: 60%;
        height: 60vh;
        margin: 0 1rem;
        background-color: #1e1e1e;
        border-radius: 7px;
        position: relative;
        overflow: auto;

        @include size_L{
            width: 70%;
        }
        @include size_M{
            width: 85%;
        }
        @include size_S{
            width: 100%;
            height: 80vh;
            border-radius: 7px 7px 0 0;
        }

        & > .close_button{
            position: absolute;
            top: 10px;
            right: 10px;
            width: 2.7rem;
            height: 2.7rem;
            font-size: 1.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            cursor: pointer;
            transition: background-color .2s ease;
    
            &:hover{
                background-color: #ffffff13;
            }
        }
    }

    & > .modal_box.small_modal{
        width: 26rem;
        height: 65vh;
        max-width: 100%;

        @include size_S{
            height: 90vh;
        }
    }

    & > .slide_from_bottom{
        animation: slide_up_from_bottom 0.4s;
    }
    @keyframes slide_up_from_bottom {
        from {
            bottom: -300px;
            opacity: 0;
        }
        
        to {
            bottom: 0;
            opacity: 1;
        }
    }
    & > .slide_down_out{
        animation: slide_down_out_animation 0.4s;
    }
    @keyframes slide_down_out_animation {
        from {
            bottom: 0;
            opacity: 1;
        }
        
        to {
            bottom: -300px;
            opacity: 0;
        }
    }
}