.notice {
  margin: 2rem;
  color: #ffffff;

  h2 {
    margin: 2rem 0;
  }
  p {
    margin: 1rem 0;
  }

  ul,
  ol {
    padding-left: 1rem;
    margin-bottom: 1rem;
  }

  li{
    margin-top: 1rem;
  }
}
